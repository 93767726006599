// Here you can add other styles

.tableHeaderBgRemove {
  --cui-table-bg: #fff0;
}

.serviceHeader {
  display: flex;
  justify-content: end;
  flex: auto;
  margin-right: 20px;
}

// @media (min-width: 768px) {
//   .sidebar.sidebar-sm:not(.sidebar-end):not(.hide)~* {
//     --cui-sidebar-occupy-start: 4rem;
//   }
// }

.avatar-img {
  border-radius: 5px;
}

.status_label > input[type="radio"] {
  cursor: pointer;
  margin-top: 8px;
}

.status_label > input[type="radio"] + label {
  cursor: pointer;
  margin-top: 4px;
}

.status_label > label:hover {
  opacity: 0.8;
}

@media (max-width: 576px) {
  .mmt-0 {
    margin-top: 0.5rem;
  }

  .mmt-1 {
    margin-top: 1rem;
  }

  .mmt-2 {
    margin-top: 2rem;
  }

  .mmt-3 {
    margin-top: 3rem;
  }

  .mmb-0 {
    margin-bottom: 0.5rem;
  }

  .mmb-1 {
    margin-bottom: 1rem;
  }

  .mmb-2 {
    margin-bottom: 2rem;
  }

  .mmb-3 {
    margin-bottom: 3rem;
  }

  .scroll_x {
    overflow-x: scroll;
  }

  .mms-1 {
    margin-left: 1rem;
  }

  .mms-2 {
    margin-left: 2rem;
  }

  .mms-3 {
    margin-left: 3rem;
  }
.pme-3{
  padding-right:20px;
}

  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
}


::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #5a27e5e0;
}

::-webkit-scrollbar-track:hover {
  background: #55555596;
}


@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1750px !important;
  }
}

@media (min-width: 1400px) {
  .landing-page-container {
    max-width: 100vw !important;
    /* Set max width to 1400px */
  }
}


.custom-modal-body {
  max-height: 400px;
  overflow-y: scroll;
}

.rbc-time-content {
  overflow-y: hidden;
}

// @media (min-width: 768px) {
//   .sidebar.sidebar-sm:not(.sidebar-end):not(.hide)~* {
//     --cui-sidebar-occupy-start: 12rem;
//   }
// }

.customblue {
  background-color: #0019f969 !important;
}

