@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

.montserrat_Font {
    font-family: "Montserrat", serif !important;
}

header {
    width: 100%;
    background-color: #512DA8;
    /* Purple background */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    /* Ensure it stays on top */
    padding: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
}

.navbar .logo {
    font-size: 24px;
    color: white;
    text-decoration: none;
}

.nav-menu {
    list-style: none;
    display: flex;
    gap: 20px;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: 0;
}

.nav-menu li a,
ScrollLink {
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding: 10px;
}

.nav-menu li a:hover {
    color: #D1C4E9;
    /* Lighter purple on hover */
}

/* Button Styles for Demo, Free Trial, and Login */
.nav-menu .btn, .bttn {
    background-color: #00BCD4;
    /* Blue buttons */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
}

.nav-menu .btn:hover {
    background-color: #0288D1;
    /* Darker blue on hover */
}

/* Hamburger Menu (Mobile) */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger span {
    height: 3px;
    width: 25px;
    background: white;
    margin-bottom: 4px;
    border-radius: 5px;
}

/* Responsive Styling for Mobile */

@media (max-width: 768px) {
    .nav-menu {
        position: absolute;
        top: 100%;
        gap: 3px;
        left: 0;
        background-color: #512DA8;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        /* Align items to the left */
        padding-left: 10px;
        /* Add padding for a clean left alignment */
        display: none;
        /* Hide menu initially */
        z-index: 999;
    }

    .nav-menu li {
        width: 100%;
        /* Ensure each menu item takes full width for a clean look */
        padding: 5px 0;
    }

    .nav-menu.active {
        display: flex;
        /* Show menu when active */
    }

    .nav-menu li a {
        width: 100%;
        /* Make links take full width */
        padding: 8px 15px;
        text-align: left;
        /* Align text to the left */
    }
 .nav-menu li .btn {
     width: auto;
     /* Allow button to size naturally */
  
     padding: 0px 15px;
     /* Medium button padding */
     text-align: left;
     margin: 5px 5px;
     /* Reduce margin for compact spacing */
     margin-bottom: 5px;
 }
 
        .hamburger {
            display: flex;
            /* Show hamburger menu */
            cursor: pointer;
            /* Indicate it's clickable */
            position: absolute;
            /* Position absolutely within the navbar */
            right: 20px;
            /* Place hamburger on the right */
        }

    body {
        padding-top: 80px;
        /* Ensure content doesn't hide under the fixed header */
    }
    
 .navbar {
     display: flex;
     justify-content: center;
     /* Center the logo */
     align-items: center;
     padding: 10px 20px;
     /* Adjust padding for mobile */
     position: relative;
     /* Needed for absolute positioning of hamburger */
 }

 .navbar .logo {
     font-size: 24px;
     color: white;
     text-decoration: none;
     margin-right: 25px;
     /* Remove left margin */
 }
}


@media (min-width: 768px) {
    .responsive-image {
        max-width: 75%;
        margin-top: 50px !important;
            margin-bottom: 50px !important;
        
    }
}


/* Responsive Styling for for web */
@media (min-width: 1200px) {
    .w-lg-75{
        width: 75% !important;
    }
    
}




/* Global Styles */
body {
    /* font-family: Arial, sans-serif; */
    margin: 0;
    padding: 0;
    background-color: #f7f7f7;
}

/* Header Section */
.main-header {
    background-color: #6A1B9A;
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-header .login-link a {
    color: white;
    text-decoration: none;
    font-size: 18px;
}

/* Hero Section */
.hero {
    background: linear-gradient(135deg, #6A1B9A, #283593);
    color: white;
    text-align: center;
    padding: 85px 20px 18px;;
    
}

.hero h1 {
    font-size: 36px;
    margin-bottom: 8px;
}

.hero p {
    font-size: 18px;
    margin-bottom: 10px;
}

.cta-buttons {
    margin-top: 20px;
}

.cta-button {
    background-color: #512DA8;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 16px;
}

.cta-button:hover {
    background-color: #283593;
}

.login-button {
    background-color: #283593;
}

/* Features Section */
.features {
    padding: 60px 20px;
    text-align: center;
}

.features h2 {
    font-size: 28px;
    color: #512DA8;
}

.small-feature-tiles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.tile {
    background-color: #512DA8;
    color: white;
    padding: 20px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
}

/* Pricing Section */
.pricing {
    padding: 60px 20px;
    background-color: #fff;
    text-align: center;
}

.pricing h2 {
    font-size: 28px;
    color: #512DA8;
}

.pricing-tiles {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.pricing-plan {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.pricing-plan h3 {
    color: #512DA8;
    margin-bottom: 10px;
}

.premium {
    border: 2px solid #512DA8;
}

/* General Testimonial Section Styling */
.testimonial-section {
    text-align: center;
    margin: 40px auto;
    max-width: 100%;
    position: relative;
}

.testimonial-slider {
    overflow: hidden;
    position: relative;
}

.testimonial-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
}

.testimonial-slide {
    min-width: 35%;
    /* Show 4 slides (25% width per video) */
    padding: 10px;
    box-sizing: border-box;
}

.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 177.78%;
    /* 9/16 ratio padding */
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Slider Controls */
.prev-slide,
.next-slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.prev-slide {
    left: 0;
}

.next-slide {
    right: 0;
}

/* Responsive */
@media (max-width: 768px) {
    .testimonial-slide {
        min-width: 50%;
        /* Show 2 slides on smaller screens */
    }
}

@media (max-width: 480px) {
    .testimonial-slide {
        min-width: 100%;
        /* Show 1 slide on mobile screens */
    }
}


/* CTA Bottom Section */
.cta-bottom {
    padding: 40px 20px;
    background-color: #512DA8;
    color: white;
    text-align: center;
}

.cta-bottom h2 {
    font-size: 24px;
}

.cta-bottom p {
    font-size: 16px;
    margin-bottom: 20px;
}

/* Footer */
footer {
    padding: 20px;
    background-color: #6A1B9A;
    color: white;
    text-align: center;
}

/* Media Queries for Mobile and Tablets */
@media (min-width: 600px) {
    .small-feature-tiles {
        grid-template-columns: repeat(3, 1fr);
    }

    .pricing-tiles {
        flex-direction: row;
        justify-content: center;
    }

    .video-testimonials {
        flex-direction: row;
    }
}

@media (min-width: 1024px) {
    .hero h1 {
        font-size: 48px;
    }

    .hero p {
        font-size: 20px;
    }

    .small-feature-tiles {
        grid-template-columns: repeat(4, 1fr);
    }

    .pricing h2 {
        font-size: 32px;
    }

    .testimonials h2 {
        font-size: 32px;
    }
}

/* Modal Styles */
.demo-modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    /* z-index: 1; */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.6);
    /* Black background with opacity */
    justify-content: center;
    align-items: center;
}

.demo-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    /* width: 90%; */
    /* max-width: 420px; */
    /* box-shadow: 0 5px 15px rgba(0,0,0,0.3); */
    position: relative;
    text-align: center;
    border: none;
}

/* Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 8px;
    top: -4px;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

/* Form Styling */
.demo-modal-content h2 {
    color: #512DA8;
    /* Purple heading */
    font-size: 24px;
    margin-bottom: 20px;
}

.demo-modal-content label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #283593;
    /* Blue label */
    text-align: left;
}

.demo-modal-content input[type="text"],
.demo-modal-content input[type="email"],
.demo-modal-content input[type="tel"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
}

.demo-modal-content button[type="submit"] {
    background-color: #512DA8;
    /* Purple submit button */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.demo-modal-content button[type="submit"]:hover {
    background-color: #283593;
    /* Darker blue on hover */
}

/* Responsive Design for Mobile */
@media (max-width: 600px) {
    .demo-modal-content {
        width: 100%;
        /* Full width on mobile */
        height: 100%;
        /* Cover the full screen on mobile */
        border-radius: 0;
        padding: 20px;
    }

    .demo-modal-content h2 {
        font-size: 22px;
    }

    .demo-modal-content button[type="submit"] {
        padding: 15px;
        font-size: 18px;
    }

    .close {
        font-size: 24px;
    }
}

/* Industry Section */
.industries-section {
    padding: 50px 0;
    text-align: center;
    background-color: #f9f9f9;
}

.industries-section h2 {
    font-size: 2em;
    color: #4A4A8B;
    /* Purple-Blue color theme */
    margin-bottom: 20px;
}

.industries-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.industry-tile {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 200px;
    transition: transform 0.3s;
}

.industry-tile:hover {
    transform: translateY(-10px);
}

.industry-tile img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
}

.industry-tile p {
    font-size: 1.1em;
    color: #333;
    font-weight: bold;
}

/* Responsive */
@media (max-width: 768px) {
    .industries-grid {
        flex-direction: column;
        align-items: center;
    }

    .industry-tile {
        width: 80%;
    }
}

/* FAQ Section */
.home-accordion-button {
    background-color: #6a5acd;
    color: white;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 1.2rem;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    /* Align items vertically */
}

.home-plus {
    margin-right: 10px;
    /* Space between the icon and text */
    font-size: 0.6rem;
    /* Adjust size as needed */
}

.home-accordion-button.active .home-plus {
    content: "-";
    /* Change plus to minus */
    color: white;
    /* Keep color consistent */
}

.home-accordion-button.active .home-plus {
    content: "-";
    /* Change plus to minus */
}

.home-accordion-content {
    padding: 15px;
    display: none;
    /* Hidden by default */
    overflow: hidden;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-top: none;
}

.trustpilot-widget {
    padding: 60px 20px;
    text-align: center;
}

.trustpilot-widget-button {
    background-color: #512DA8;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}